<template>
  <div class="wrapper">
    <div class="title">头痛头晕类药品销售明细</div>
    <div class="tab_head ds_flex al_center">
      <div class="td">药品名称</div>
      <div class="td icon_up">销量<span class="icon"></span></div>
      <div class="td icon_down">成交额<span class="icon"></span></div>
    </div>
    <div class="list">
      <div class="item ds_flex al_center">
        <div class="td">药品A</div>
        <div class="td">1200盒</div>
        <div class="td">¥<span class="amt">300.00</span></div>
      </div>
      <div class="item ds_flex al_center">
        <div class="td">药品A</div>
        <div class="td">1200盒</div>
        <div class="td">¥<span class="amt">300.00</span></div>
      </div>
      <div class="item ds_flex al_center">
        <div class="td">药品A</div>
        <div class="td">1200盒</div>
        <div class="td">¥<span class="amt">300.00</span></div>
      </div>
    </div>
  </div>
</template>
<script>
  let self = null;
  export default {
    components: {
    },
    data() {
      return {
        chart: null,
      }
    },
    created() {
      self = this;
    },
    methods: {
    }
  }
</script>
<style lang="less" scoped>
  .ds_flex {
    display: flex;
    display: -webkit-flex;
  }
  .ju_center {
    justify-content: center;
    -webkit-justify-content: center;
  }
  .al_center {
    align-items: center;
    -webkit-align-items: center;
  }
  .wrapper {
    min-height: 100vh;
    background: #f8f8f8;
  }
  .title {
    padding: .3rem 0;
    font-size: .28rem;
    color: #333333;
    text-align: center;
    background: #fff;
    border-bottom: 1px solid #F7F6F8;
  }
  .tab_head {
    padding: .27rem .32rem;
    background: #fff;
    font-size: .3rem;
    color: #333333;
    border-bottom: .15rem solid #F8F8F8;
    .td {
      flex: 1;
      -webkit-flex: 1;
      text-align: center;
      .icon {
        margin-left: .15rem;
        display: inline-block;
        width: .13rem;
        height: .24rem;
        background: url('../../assets/img/normal.png') no-repeat center / 100% 100%;
      }
    }
    .td:first-child {
      text-align: left;
    }
    .td:last-child {
      text-align: right;
    }
    .icon_up {
      .icon {
        background: url('../../assets/img/high.png') no-repeat center / 100% 100%;
      }
    }
    .icon_down {
      .icon {
        background: url('../../assets/img/low.png') no-repeat center / 100% 100%;
      }
    }
  }
  .list {
    padding: 0 0.32rem;
    background: #fff;
    .item {
      padding: .35rem 0;
      font-size: .28rem;
      color: #555555;
      border-bottom: 1px solid #F7F6F8;
      .td {
        flex: 1;
        -webkit-flex: 1;
        text-align: center;
      }
      .td:first-child {
        text-align: left;
      }
      .td:last-child {
        text-align: right;
      }
    }
    .item:last-child {
      border-bottom: none;
    }
  }
</style>
